<template>
  <div>
    <v-row>
      <v-spacer />
      <v-col cols="8">
        <DTP
          :init_value="idate_start"
          label="日期1"
          v-model="idate_start"
          @confirm="calculate"
        >
        </DTP>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-spacer />
      <v-col cols="8">
        <DTP 
          label="日期2"
          v-model="idate_end" 
          @confirm="calculate"> </DTP>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col cols="12">
        {{ delta }}
      </v-col>
    </v-row>
  </div>
</template>
<script>

import DTP from "./dtPickComb"

export default {
  name: "m-timeCalu",
  components: {
    DTP,
  },
  props: {},
  data() {
    let [y,m,d]=new Date().toLocaleDateString().split('/');
    let idate_start=`${y}-${m.padStart(2,'0')}-${d.padStart(2,'0')}`;
    return {
      idate_start,
      idate_end: "",
      delta: "",
    };
  },
  watch:{

  },
  methods: {
    calculate() {
      if (this.idate_start && this.idate_end) {
        let ds = new Date(this.idate_start);
        let de = new Date(this.idate_end);
        let day = (de - ds) / 1000 / 60 /60 /24;
        this.delta = ` ${day}天 `;
      }
    },
  },
};
</script>
