import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: { 
      main_service_fail:false,
  },
  mutations: {
      mschange(state,val){
          state.main_service_fail=val;
      }
  },
  actions: {
  },
  getters:{
  }
});