<template>
  <v-app>
    <v-app-bar height="30" app color="primary" dark>
      <MHEADER> </MHEADER>
    </v-app-bar>
    <v-main>
      <PANBAN/>
      <STANSHEET/>
      <FOOTER/>
    </v-main>
  </v-app>
</template>

<script>

import PANBAN from '@/components/rsideTools';
import STANSHEET from '@/components/stan_sheet';
import MHEADER from "@/components/vheader";
import FOOTER from "@/components/footer";

export default {
  name: 'App',

  components: {
    PANBAN,
    STANSHEET,
    MHEADER,
    FOOTER,
  },

  data: () => ({

  }),
  methods:{
  }
};
</script>
