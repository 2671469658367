<template>
  <div>
    <tr
     v-for="(vs, index) in month_data_last" 
     :key="'k3' + index">
      <td 
      style="width:58px"  
      v-for="v in vs"  
      :key="'l1' + v.dyv.number">
        <slot :val="v"> </slot>
        <li
          v-if="!none_number"
          :style="subweekstyle(v)"
           style="
            text-align: center;
            font-size: 15px;
            list-style: none;
            background: cornflowerblue;
          "
        >
          <b>{{ v.dyv.number }}</b> .
          <sub> {{v.dyv.week}}</sub>
        </li>
      </td>
    </tr>
  </div>
</template>
<script>
export default {
  name: "pb_cells",
  props: {
    month_data_last: Array,
    none_number:Boolean
  },
  data() {
    return {};
  },
  methods: {
    subweekstyle(v){
        let sty={};
        if(v.dyv.week=="六"||v.dyv.week=="日"){
            sty.color="#BBBBBB";
        }
        if(v.fous){
            sty.background="darkblue";
            sty.color="white";
        }
        return sty;
    }
  },
};
</script>
