<template>
  <v-menu
    ref="menu_rds"
    v-model="menu_ds"
    :close-on-content-click="false"
    :return-value.sync="value"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="value"
        @change="$emit('change',value)"
        readonly
        :label="label"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="value"
      @change="$emit('change',value)"
      locale="zh-cn"
      no-title
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu_ds = false"> 取消 </v-btn>
      <v-btn text color="primary" @click="save">
        确认
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
      init_value:String,
      label:String,
  },
  model:{
      prop:'value',
      event:'confirm',
  },
  data(){
      return{
          value:this.init_value,
          menu_ds:false,
      }
  },
  methods:{
      save(){
          this.$refs.menu_rds.save(this.value);
          this.$emit("confirm",this.value);
      }
  }
};
</script>