<template>
  <v-row no-gutters align="center" class="ma-0 pa-0">
    <v-col cols="12" sm="4" md="1">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item link :disabled="ismsfail">
            <v-list-item-title @click="menu_exportxls.action">
              {{ menu_exportxls.title }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item link :disabled="ismsfail">
            <v-list-item-title @click="menu_datequery.action">
              {{ menu_datequery.title }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-title @click="menu_personquery.action">
              {{ menu_personquery.title }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-divider></v-divider>
          </v-list-item>
           <v-list-item link>
            <v-list-item-title @click="menu_plt.action">
              {{ menu_plt.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="4" md="11">
      <span align="center">
        <!-- <B>title</B> -->
      </span>
      <ExportDlg
        :dialog="exportDlg"
        @change="exportDlg = !exportDlg"
      ></ExportDlg>
      <DateExport
        :dialog="dateExport"
        @change="dateExport = !dateExport"
      ></DateExport>
      <PersonQuery
        :dialog="personShow"
        @change="personShow = !personShow"
      ></PersonQuery>
    </v-col>
  </v-row>
</template>

<script>
import ExportDlg from "./exportDlg";
import DateExport from "./dateExport";
import PersonQuery from "./personQuery";

export default {
  name: "m-header",
  components: {
    ExportDlg,
    DateExport,
    PersonQuery,
  },
  props: {},
  data() {
    return {
      menu_exportxls: {title:"  导出Excel   " , action:this.exportExcel},
      menu_datequery: { title: "  按日期查询   ", action: this.dateExportShow },
      menu_personquery: { title: "  按人员查询   ", action: this.personQuery },
      menu_plt:{ title:"  驻外时间表   ",action:this.pltShow },
      exportDlg: false,
      dateExport: false,
      personShow: false,
    };
  },
  computed: {
    ismsfail(){
      return this.$store.state.main_service_fail;
    },
  },
  mounted() {},
  methods: {
    exportExcel() {
      this.exportDlg = true;
    },
    dateExportShow() {
      this.dateExport = true;
    },
    personQuery() {
      this.personShow = true;
    },
    pltShow(){
      const url = "plt.html";
      window.open(url,"_blank");
    },
  },
};
</script>
<style>
</style>