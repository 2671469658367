<template>
  <v-chip
    small
    label
    :color="cellColor"
    :link="hasLunHuang"
    :title="LunHuangMessage"
    @click.stop="hasLunHuang?viewTitle=true:null"
  >
    {{ mbobj.name }} {{ hasLunHuang ? "✉" : "" }}
    <!-- <v-icon right>mdi-account-outline</v-icon> -->
    <v-snackbar v-model="viewTitle"> {{ LunHuangMessage }}</v-snackbar>
  </v-chip>
</template>

<script>
import { lhParse } from "@/js/dts.js";


export default {
  props: {
    mbobj: Object,
  },
  data() {
    return {
      viewTitle: false,
    };
  },
  computed: {
    LunHuangMessage() {
      if(this.hasLunHuang){
        let lp= lhParse(this.mbobj);
        return `${lp.mes} ${lp.text}`;
      }else {
        return "";
      }
    },

    hasLunHuang(){
        return this.mbobj.cmd.endsWith("ing");
    },

    cellColor(){
      return this.mbobj.grade.includes("机械员")
        ? "blue-grey lighten-4"
        : "deep-orange lighten-4";
    },
  },
  methods: {},
  filters: {},
};
</script>
<style>
</style>