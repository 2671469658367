import axios from "axios"
let root_serve_url='https://www.jojogos.ltd:5050';
let base_server_url = root_serve_url+'/stanp';

// root_serve_url='http://localhost:7171';//服务端 测试时
// base_server_url = 'http://localhost:7171/stanp';//服务端 测试时

console.log("BASE_SERVER_URL:", base_server_url);

export function ax_get(rout, params, thenDu, otherUrls = base_server_url,errorDu=null) {
  axios
    .get(otherUrls + rout, {
      params,
    })
    .then(thenDu)
    .catch(function (error) {
      if(errorDu){
        errorDu(error);
      }else{
        console.log(error);
      }
    });
}

// function ax_post(rout, data, thenDu, otherUrl = base_server_url) {
//   axios({
//     url: otherUrl + rout,
//     method: "post",
//     data: data,
//     // headers: { "Content-Type": "application/json" },
//     headers: { "Content-Type": "application/x-www-form-urlencoded" },
//   })
//     .then(thenDu)
//     .catch(function (error) {
//       console.log(error);
//     });
// }

//   let { item, value, op } = condition?JSON.parse(condition,'utf-8'):{};
export function tbGet({ fields, item, value, op = 'eq' }, table = 'stanp') {
  return new Promise(rel => {
    ax_get("/tbGet", {
      fields, table, item, value, op,

    }, res => rel(res.data));
  });
}

function dfCallback(cb) {
  return (res) => {
    if (cb) {
      cb(res.data);
    } else {
      console.log(res);
    }
  }
}

export function StanpAtget(callback,failCallback) {
    ax_get("/cmd/at", {}, dfCallback(callback),undefined,failCallback)
}

export function StanpOnStaionsGet(callback) {
  ax_get("/distinct/station", { item: 'cmd', value: 'on-zyz' }, dfCallback(callback))
}

export function AllNamesGet(callback) {
  ax_get("/distinct/name", {}, dfCallback(callback));
}

export function StanpAtDate(date,callback){
  let params={
    table:"stanp",
    fields:"name,station,grade,belong,cmddate,levdate,cmd",
    dateOne:"cmddate",
    dateTow:"levdate"
  }
  ax_get("/date/"+date,params,dfCallback(callback))
}

function downloadfilefromaliservice(urlFileName) {
  const a = document.createElement("a");
  a.href = urlFileName;
  a.target = "_blank";
  a.click();
}

export function exportToxls(mode,params){
  let reqpath=root_serve_url+"/export"+mode+"?";
  for(let [pn,pv] of Object.entries(params)){
      reqpath+=`${pn}=${pv}&`
  }
  downloadfilefromaliservice(reqpath); 
}




