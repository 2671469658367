<template>
  <v-row justify="center">
    <v-dialog v-model="isshow" persistent max-width="750px">
      <v-card>
        <v-card-title>
          <span class="headline">按指定日期查询人员名单</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <DTP
                  :init_value="idate_start"
                  label="目标日期"
                  v-model="idate_start"
                  @confirm="datechange"
                >
                </DTP>
              </v-col>
            </v-row>
            <p>
              <small>
                *选择一个日期，点击查询，显示日期当天所有外站的驻外人员名单。
              </small>
              <span v-if="selecterror" class="red" style="color: white">
                *日期选择范围不正确，应小于等于当前日期*
              </span>
            </p>
            <v-row > 
              <v-card height="480px" elevation="0">
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
                
              ></v-progress-circular>
              </v-card>
              <v-simple-table v-if="!loading" dense fixed-header height="480px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="(name, i) in colnames" :key="i">{{ name }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(r, j) in resultes" :key="j">
                      <td>{{ j + 1 }}</td>
                      <td>
                        {{ r.name }}
                      </td>
                      <td>
                        {{ r.station }}
                      </td>
                      <td>
                        {{ r.grade }}
                      </td>
                      <td>
                        {{ r.belong }}
                      </td>
                      <td>
                        {{ r.cmddate | shortdate }}
                      </td>
                      <td>
                        {{ r.levdate | shortdate }}
                      </td>
                      <td>
                        {{ r.cmd | toStatus }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="isshow = false">
            关闭
          </v-btn>

          <v-btn
            color="blue darken-1"
            :disabled="!canexport"
            text
            @click="doexport"
          >
            导出EXCEl
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import DTP from "./dtPickComb";
import { exportToxls, StanpAtDate } from "../js/axreqs";

function toAvaString(dateobj) {
  let [y, m, d] = dateobj.toLocaleDateString().split("/");
  return `${y}-${m.padStart(2, "0")}-${d.padStart(2, "0")}`;
}

export default {
  props: {
    dialog: Boolean,
  },
  components: {
    DTP,
  },
  data() {
    let idate_start = toAvaString(new Date());
    return {
      cSelete: null,
      idate_start,
      selecterror: false,
      resultes: [],
      loading: false,
      colnames: [
        "序号",
        "姓名",
        "站点",
        "岗位",
        "隶属",
        "驻外日期",
        "撤离日期",
        "状态",
      ],
    };
  },
  computed: {
    canexport() {
      return !this.selecterror && this.resultes.length > 0;
    },
    isshow: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("change", !!val);
      },
    },
  },
  watch: {
    isshow(v) {
      if (v) {
        this._load();
      }
    },
  },
  filters: {
    shortdate(v) {
      let d = new Date(v);
      if (v && !isNaN(d.getTime())) {
        return d.toLocaleDateString();
      } else {
        return "";
      }
    },
    toStatus(v) {
      return {
        "on-zyz": "驻外中",
        "on-cl-ing": "撤离中",
        "th-ing": "派遣中",
        "zp-ing": "增派中",
        "on-bth-ing": "被替换",
        ycl: "已撤离",
      }[v];
    },
  },
  methods: {
    _load() {
      if (!this.selecterror) {
        this.loading = true;
        StanpAtDate(this.idate_start+" 00:00:00", (res) => {
          res.items.sort((a, b) => a.station.localeCompare(b.station));
          this.resultes = res.items;
          this.loading = false;
        });
        //TODO添加主服务进程失效后的java实现，当前不正确。
      }
    },
    datechange(ev) {
      let cd = new Date(ev);
      // let ld=new Date("2021-1-1");
      let nd = new Date();
      // this.selecterror=cd>nd||cd<ld;
      this.selecterror = cd > nd;
      this._load();
    },
    doexport() {
      exportToxls("/fromdate", { date: this.idate_start });
    },
  },

  mounted() {},
};
</script>