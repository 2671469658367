export function getNowStr(d=new Date()) {
  return `${d.toLocaleDateString().replace(/\//g,"-")} ${String(d.getHours()).padStart(2,'0')}:${String(d.getMinutes()).padStart(2,'0')}`;
}


export function getDatePart(dt_str) {
  if(dt_str){
    return dt_str.substr(0,10);
  }else{
    return "";
  }
}


export function getTimePart(dt_str) {
    if(dt_str){
    return dt_str.substr(-5);
  }else{
    return "";
  }
}


const mapCmd=new Map([
  ['th-ing',{label:'P',icon:"mdi-account-convert"}],
  ['zp-ing',{label:'Z',icon:"mdi-account-multiple-plus-outline"}],
  ['on-cl-ing',{label:'C',icon:"mdi-account-multiple-minus-outline"}],
]
);

export function lhParse(lhmess){
  
  let {name,cmd,cmddate,plandate,station,iidinfo} = lhmess;
  let CMnum=(x)=>x>0?Math.ceil(x):Math.floor(x);
  let nd=new Date();
  
  let lab=mapCmd.get(cmd);
  let targetDate=lab.label=="C"?plandate:cmddate;
  let delt=CMnum((new Date(targetDate)-new Date(nd.toDateString()))/3600000/24);
  
  let textColor="";
  let rel={mes:`${station} : ${targetDate} (${delt})`,delt:delt};
  rel.icon=lab.icon;
  
  if(delt<=7 && delt>3){
    textColor="yellow lighten-4";
  }else if(delt<=3 &&delt>0){
    textColor="orange lighten-3";
  }else if(delt<=0){
    textColor="deep-orange lighten-2";
  }
  rel.textColor=textColor;

  switch(lab.label){
    case 'P':
      rel.text=`${name} 替换 ${iidinfo.split(',')[0]}`;
      break;
    case 'Z':
      rel.text=`${name} 增派 `;
      break;
    case 'C':
      rel.text=`${name} 撤离 `;
      break;
    default:
      rel.text="未监控错误";
      break;
  }
 
  return rel;
}




