<template>
    <v-card>
        <v-card-text>
           <p class="text-center">
               ©2021 Frclso
               
               <a v-if="ishttps" class="text-center" href="https://beian.miit.gov.cn/" garget="_blank">
                   粤ICP备2021036352号-1
               </a>
            </p>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
  name: "ifooter",
  props: {},
  data() {
    return {
      ishttps:document.location.protocol==='https:'
    };
  },
  methods: {},
};
</script>
<style>
    
</style>