<template>
  <v-row justify="center">
    <v-dialog v-model="isshow" persistent max-width="600px">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template> -->
      <v-card>
        <v-card-title>
          <span class="headline">导出人员名单</span>
          <small>( Excel文件 )</small>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <!-- <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Legal middle name"
                  required
                  hint="example of helper text only on focus"
                ></v-text-field>
              </v-col> -->
              <!--     
              <v-col cols="12"  sm="6">
                <v-select
                  :items="['0-17', '18-29', '30-54', '54+']"
                  label="Age*"
                  value="全部"
                  required
                ></v-select>
              </v-col> -->
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="stationNames"
                  v-model="cSelete"
                  label="选择导出站点"
                  :rules="[v=>v.length>0||'没有输入站点']"
                  multiple
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <small>*选择导出站点后点击“导出”.</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="isshow = false">
            关闭
          </v-btn>
          <v-btn color="blue darken-1" text @click="doexport"> 导出 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { StanpOnStaionsGet,exportToxls } from "@/js/axreqs.js";

export default {
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      stationNames: [],
      cSelete: ["stanp_now"],
    };
  },
  computed: {
    isshow: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("change", !!val);
      },
    },
  },
  methods: {
    doexport() {
      if(this.cSelete.length==0){
        return;
      }
      let params={},mode="";

      if (this.cSelete.length==this.stationNames.length) {
        mode = "/all";
      } else {
        mode = "/fromstation";
        params.stations = this.cSelete.join("_");
      }
      
      exportToxls(mode,params);
    }
  },
  created() {   
    StanpOnStaionsGet(getstns=>{
      this.stationNames = getstns.map(sn => ({text: sn, value: sn}));
      this.cSelete = Array.from(getstns);
    });                                                   
  },
};
</script>