<template>
  <div class="p_later">
    <a href="javascript:void(0)" @click="isShow = !isShow">
      {{ isShow ? "▲" : "▼" }}</a
    ><br />
    <div v-if="isShow">
      <select
        @change="toolChange"
        v-model="toolchoice"
        class="lightblue sel rounded"
      >
        <option value="timeCalu">时间计算▼</option>
        <option value="dateCalu">日期计算▼</option>
        <!-- <option value="folowFlight">跟机小时时长</option> -->
      </select>

      <DateCaul v-if="toolchoice == 'dateCalu'" />
      <TimeCaul v-else-if="toolchoice == 'timeCalu'" />
    </div>
  </div>
</template>

<script>
import DateCaul from "./dateCaul";
import TimeCaul from "./timeCaul";

export default {
  components: {
    DateCaul,
    TimeCaul,
  },
  data() {
    return {
      isShow: false,
      toolchoice: "timeCalu",
    };
  },
  methods: {
    toolChange() {},
  },
};
</script>
<style>
.p_later {
  
  margin-left: 0%;
  width: 100%;
}
.sel {
  border: 1px solid #5d85a3;
  /*清除原样式*/
  -webkit-appearance: none; /* google */
  -moz-appearance: none; /* firefox */
  appearance: none; /* IE */
  /*css添加select背景色的方法*/
  background-color: rgb(229, 236, 236);
}
</style>