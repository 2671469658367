<template>
  <div>
    <p/>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="6">
        <v-text-field
          label="从时间："
          dense
          v-model="itime_start"
          @change="calculate"
        ></v-text-field>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-spacer />
      <v-col cols="6">
        <v-text-field
          label="到时间："
          dense
          v-model="itime_end"
          @change="calculate"
        ></v-text-field>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col cols="12">
        {{ delta }}
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { getNowStr, getTimePart } from "@/js/dts.js"

function stv(timeStr) {
  return (
    !!timeStr &&
    typeof timeStr === "string" &&
    /^(([0-2][0-3])|([0-1]?[0-9]))(:|：)?[0-5][0-9]$/.test(timeStr.trim())
  );
}

export default {
  name: "m-timeCalu",
  props: {},
  data() {
    return {
      itime_start: getTimePart(getNowStr()),
      itime_end: "",
      delta: "",
    };
  },
  methods: {
    calculate() {
      if (stv(this.itime_start) && stv(this.itime_end)) {
        let sit = (st) => st.trim().replace(/[^0-9.]/g, "").padStart(4, "0");
        let [its, ite] = [sit(this.itime_start), sit(this.itime_end)];
        let sh = Number(its.slice(0, 2));
        let sm = Number(its.slice(-2));
        let eh = Number(ite.slice(0, 2));
        let em = Number(ite.slice(-2));
        if(eh*60+em<sh*60+sm){
            eh+=24;
        }
        let minite = eh * 60 + em - sh * 60 - sm;

        this.delta = ` ${Number.parseInt(minite / 60)}小时${Math.abs(
          minite % 60
        )}分钟`;
      } else {
        this.delta = "无效输入";
      }
    },
  },
};
</script>
<style >

</style>
