<template>
  <div>
    <v-card>
      <!-- <v-card-title class="headline">驻外人员名单</v-card-title> -->
      <v-card-title>
        <span>外站人员名单</span>
        <span class="warntext">{{ serviceMsg }}</span>
      </v-card-title>
      <v-btn :disabled="extend" @click="all" small color="gray" class="ma-2">
        展开
        <v-icon>mdi-arrow-down</v-icon>
      </v-btn>
      <v-btn :disabled="!extend" @click="none" small color="gray" class="ma-2">
        收起
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>

      <v-card-text>
        <v-expansion-panels focusable v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <!-- sd_en0,sd_me1,sd_tl2,pq_en3,pq_me4,pq_tl5,
t1_en6, t1_me7,t2_en8,t2,me9,t3_en10,t3_me11,t4_en12,t4_me13,
cq_en14,cq_me15,cq_tl=16 -->
              <v-row no-gutters>
                <v-col cols="12" md="12" sm="5">
                  <v-chip color="green" small label> 所有外站 </v-chip>
                  <v-chip color="primary" small>{{ `总人数${counts}` }}</v-chip><br/>
                  <v-chip color="blue" small style="color:white">驻外{{ zw_counts }}人</v-chip>
                  <v-chip color="primary" small>属地{{ sd_counts }}人</v-chip>                
                  <v-chip color="primary" small>出差{{ cc_counts }}人</v-chip>
                  <br />
                  
                  <v-chip color="blue" small style="color:white">航线派遣{{ allStiaionCpts[5] }}人</v-chip>
                  <v-chip color="blue" small style="color:white">其他派遣{{ allStiaionCpts[16] }}人</v-chip>
                  
                  
                  
                </v-col> 
                <v-col cols="12" md="12" sm="5">
                  <v-banner elevation="2" class="text-overline">
                    ◇属地放行{{ allStiaionCpts[0] }}人，属地机械员{{
                      allStiaionCpts[1]
                    }}人<br />
                    ◇其他放行{{ allStiaionCpts[14] }}人，其他机械员{{
                      allStiaionCpts[15]
                    }}人<br />
                    ◇一队派遣:放行{{ allStiaionCpts[6] }}人，机械员{{
                      allStiaionCpts[7]
                    }}人<br />
                    ◇二队派遣:放行{{ allStiaionCpts[8] }}人，机械员{{
                      allStiaionCpts[9]
                    }}人<br />
                    ◇三队派遣:放行{{ allStiaionCpts[10] }}人，机械员{{
                      allStiaionCpts[11]
                    }}人<br />
                    ◇四队派遣:放行{{ allStiaionCpts[12] }}人，机械员{{
                      allStiaionCpts[13]
                    }}人<br />
                    <small class="text-caption">(*其他：指非中队派遣或长期派遣人员)</small>
                  </v-banner>
                </v-col>
              </v-row>
              <!-- ♠ ♣ ♧ ♡ ♥ ❤ ❥ ❣ ♂ ♀ ✲ ☀ ☼ ☽☾ ◐ ◑ ☺ ☻ ☎ ☏ ✿ ❀ ✎ ✐ ✌ ✍ ☜ ☞ ♋ ☄
 ☊ ☋ ☌☍☢ ⁂ 〄 ↂ ❦❧ ஐ ﻬ ๑ ♈ ღ ✟ ☂ ♨ ☃ ۩ ♟ ✙ ✈ ✉ ✁ 〠 ⊹⊱⋛⋋ ⋌
 ⋚⊰⊹ ❆ ❁ ☸ ☑ ☒ ♀♂‖$ @ * & # ※ 卍 卐 Ψ № ♫ ♬ ♭ ♩ ♪ ♯ ♮ ⌒ ¶ ∮ 
 ‖ € ￡ ¥ $ ☜☞☎☏✄☪☣☢☠❦❧☭✐✌✍✓✔✕✖♂♀♥♠♣♦♧♤♨☪✎✟ஐ♈ ⓛⓞⓥⓔ ๑۩۩۩۩๑ ๑۩۞۩๑ ღღღ ￡Ю§ -->
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table dense fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr class="text-left">
                      <th>序号</th>
                      <th>姓名</th>
                      <th>岗位</th>
                      <th>隶属</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in sts" :key="i" class="text-left">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.grade }}</td>
                      <td>
                        {{ `${item.belong}:${item.station}` }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-for="(sGroup, j) in GroupByStation" :key="j">
            <v-expansion-panel-header>
              <v-row no-gutters>
                <v-col cols="12" md="12" sm="5">
                  <v-chip color="green" small label>
                    {{ sGroup.stationName }}
                  </v-chip>
                  <v-chip color="primary" small>
                    负责人：{{ sGroup.charger }} </v-chip
                  ><br />
                  <v-chip color="blue" small>
                    {{ sGroup.counts | fixTowNumber }}人
                  </v-chip>

                  <v-chip color="blue" small>
                    放行{{ sGroup.fxCounts | fixTowNumber }}人
                  </v-chip>
                  <v-chip color="blue" small>
                    机械员{{ sGroup.jxyCounts | fixTowNumber }}人
                  </v-chip><br/>
                   <v-chip color="primary" small >
                    属地{{ sGroup.sdCounts | fixTowNumber }}人
                  </v-chip>
                  <v-chip color="primary" small >
                    驻外{{ sGroup.zwCounts | fixTowNumber }}人
                  </v-chip>
                </v-col>

                <v-col cols="12" md="12" sm="5">
                  <v-banner elevation="2" class="text-subtitle-2">
                    <!-- <v-icon slot="icon" color="warning" size="25">
                    mdi-account
                  </v-icon> -->
                    <span v-for="(mb, z) in sGroup.members" :key="z">
                      <MBCELL :mbobj="mb" />
                    </span>
                  </v-banner>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table dense fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr class="text-left">
                      <th>序号</th>
                      <th>姓名</th>
                      <th>岗位</th>
                      <th>隶属</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, k) in sGroup.members"
                      :key="k"
                      class="text-left"
                    >
                      <td>{{ k + 1 }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.grade }}</td>
                      <td>
                        {{ item.belong }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-text-field
                readonly
                dense
                rounded
                height="10"
                no-resize
                class="text-caption"
                :value="`☆${sGroup.stationName}外站人数统计：`"
              >
              </v-text-field>
              <div class="totalshow"> 
              <table class="sd">
                <tr>
                  <th>按隶属统计</th>
                  <th>属地</th>
                  <th>派遣</th>
                  <th>其他</th>
                  <th>一共</th>
                </tr>
                <tr>
                  <th>放行</th>
                  <td>{{ sGroup.totals.sdfx }}</td>
                  <td>{{ sGroup.totals.pqfx }}</td>
                  <td>{{ sGroup.totals.cqfx }}</td>
                  <td>
                    {{
                      sGroup.totals.sdfx +
                      sGroup.totals.pqfx +
                      sGroup.totals.cqfx
                    }}
                  </td>
                </tr>
                <tr>
                  <th>机械员</th>
                  <td>{{ sGroup.totals.sdjxy }}</td>
                  <td>{{ sGroup.totals.pqjxy }}</td>
                  <td>{{ sGroup.totals.cqjxy }}</td>
                  <td>
                    {{
                      sGroup.totals.sdjxy +
                      sGroup.totals.pqjxy +
                      sGroup.totals.cqjxy
                    }}
                  </td>
                </tr>
                <tr>
                  <th>总计</th>
                  <td>{{ sGroup.totals.sdzrs }}</td>
                  <td>{{ sGroup.totals.pqzrs }}</td>
                  <td>{{ sGroup.totals.cqzrs }}</td>
                  <td>
                    {{
                      sGroup.totals.sdzrs +
                      sGroup.totals.pqzrs +
                      sGroup.totals.cqzrs
                    }}
                  </td>
                </tr>
              </table>
              </div>
              <div class="sepdived">
              </div>

              <div class="totalshow">
              <table class="pq">
                <tr>
                  <th>按中队汇总</th>
                  <th>一队</th>
                  <th>二队</th>
                  <th>三队</th>
                  <th>四队</th>
                </tr>
                <tr>
                  <th>放行</th>
                  <td>{{ sGroup.totals.pq1fx }}</td>
                  <td>{{ sGroup.totals.pq2fx }}</td>
                  <td>{{ sGroup.totals.pq3fx }}</td>
                  <td>{{ sGroup.totals.pq4fx }}</td>
                </tr>
                <tr>
                  <th>机械员</th>
                  <td>{{ sGroup.totals.pq1jxy }}</td>
                  <td>{{ sGroup.totals.pq2jxy }}</td>
                  <td>{{ sGroup.totals.pq3jxy }}</td>
                  <td>{{ sGroup.totals.pq4jxy }}</td>
                </tr>
                <tr>
                  <th>总计</th>
                  <td>{{ sGroup.totals.pq1jxy + sGroup.totals.pq1fx }}</td>
                  <td>{{ sGroup.totals.pq2jxy + sGroup.totals.pq2fx }}</td>
                  <td>{{ sGroup.totals.pq3jxy + sGroup.totals.pq3fx }}</td>
                  <td>{{ sGroup.totals.pq4jxy + sGroup.totals.pq4fx }}</td>
                </tr>
              </table>
            </div>

            </v-expansion-panel-content>
          </v-expansion-panel>


          <v-expansion-panel >
            <v-expansion-panel-header>
               <p>短期出差人员</p>
              <v-banner elevation="2" class="text-subtitle-2">
              <v-row no-gutters v-for="(mb, z) in ccs" :key="z">
                
                  <v-chip  small  label>
                      {{ mb.name }} {{ mb.station }} 
                      （{{mb.belong}} {{mb.grade}} ）     
                 </v-chip><br/>
               
               </v-row>    
            </v-banner>          
            </v-expansion-panel-header>
          </v-expansion-panel>



        </v-expansion-panels>
        <v-list>
          <v-list-item>
            <v-list-item-title> 近期轮换： </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item v-for="(val, index) in lunhuangList" :key="index">
          <v-list-item-content> {{val}} </v-list-item-content>
        </v-list-item> -->
          <v-list-item v-for="(val, index) in lunhuangList" :key="index">
            <v-list-item-icon>
              <v-icon color="indigo">
                {{ val.icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-button">
                {{ val.text }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span :class="val.textColor">
                  {{ val.mes }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close_func"> 关闭</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions> -->
    </v-card>
  </div>
</template>
<script>
import Stanp from "@/js/stanp.js";
import { StanpAtget } from "@/js/axreqs.js";
import MBCELL from "./member_cell";
import { lhParse } from "@/js/dts.js";

export default {
  components: {
    MBCELL,
  },
  props: {},
  data() {
    return {
      panel: [],
      sts: [],
      ccs:[],
      extend: false,
      lunhuangList: [],
    };
  },
  computed: {
    StationNames() {
      return [...new Set(this.sts.map((a) => a.station))];
    },
    GroupByStation() {
      return this.StationNames.map((n) => {
        let members = this.getBy("station", n)
                          .sort((a,b)=>a.belong.localeCompare(b.belong));
        let findedIndex=members.findIndex(p=>p.grade.includes("负责") || p.grade.includes("管理"));
        if(findedIndex>=0){
          let tcharge=members[findedIndex];
          members.splice(findedIndex,1);
          members.sort((a,b)=>a.belong.localeCompare(b.belong));
          members.unshift(tcharge);
        }

        return {
          members,
          stationName: n,
          counts: members.length,
          fxCounts: members.filter((a) => a.grade.includes("放行")).length,
          jxyCounts: members.filter((a) => a.grade.includes("机械员")).length,
          totals: this.getTotals(members),
          sdCounts:members.reduce((t,c)=>c.belong.includes(c.station)?t+1:t,0),
          zwCounts:members.reduce((t,c)=>!c.belong.includes(c.station)&&!c.name.includes('出差')?t+1:t,0),
          charger: findedIndex>=0?members[0].name:"",
        };
      });
    },
    allStiaionCpts() {
      return this.getTotals(this.sts).value.map((a) =>
        String(a).padStart(2, "0")
      );
    },
    counts() {
      return this.sts.length;
    },
    sd_counts(){
      return this.allStiaionCpts[2];
    },
    zw_counts(){
      return this.counts-this.sd_counts-this.cc_counts;
    },
    cc_counts(){
      return this.ccs.length;
    },
    ismsfail(){
      return this.$store.state.main_service_fail;
    },
    serviceMsg(){
      if(this.ismsfail){
        return `(主服务器连接失效，不能使用导出和日期查询功能)`;
      }else{
        return '';
      }
    }
  },
  methods: {
    getBy(field, value, attrid = null) {
      return this.sts
        .filter((a) => a[field] == value)
        .map((b) => (attrid ? b[attrid] : b));
    },
    all() {
      this.panel = [...this.StationNames, "ALL"].map((k, i) => i);
      this.extend = true;
    },
    // Reset the panel
    none() {
      this.panel = [];
      this.extend = false;
    },
    // constructor(name,station,grade,isLocal,szxTeam){
    getTotals(items) {
      let ots = Array(17).fill(0);
      for (let v of items) {
        if (v.belong.includes(v.station)) {
          ots[0] += v.grade.includes("放行");
          ots[1] += v.grade.includes("机械员");
        } else {
          if (v.grade.includes("放行")) {
            ots[14] += ["长期", "其他", "大修"].includes(v.belong);
            ots[6] += v.belong === "一中队";
            ots[8] += v.belong === "二中队";
            ots[10] += v.belong === "三中队";
            ots[12] += v.belong === "四中队";
          } else if (v.grade.includes("机械员")) {
            ots[15] += ["长期", "其他", "大修"].includes(v.belong);
            ots[7] += v.belong === "一中队";
            ots[9] += v.belong === "二中队";
            ots[11] += v.belong === "三中队";
            ots[13] += v.belong === "四中队";
          }
        }
      }
      ots[2] = ots[0] + ots[1]; //属地总人数
      ots[3] = ots[6] + ots[8] + ots[10] + ots[12]; //派遣放行
      ots[4] = ots[7] + ots[9] + ots[11] + ots[13]; //派遣机械员
      ots[5] = ots[3] + ots[4]; //派遣总人数
      ots[16] = ots[14] + ots[15]; //其他总人数
      
      // let sd_en0,sd_me1,sd_tl2,pq_en3,pq_me4,pq_tl5,
      //t1_en6, t1_me7,t2_en8,t2,me9,t3_en10,t3_me11,t4_en12,t4_me13,
      //cq_en14,cq_me15,cq_tl=16
      return {
        sdfx: ots[0],
        sdjxy: ots[1],
        sdzrs: ots[2],
        pqfx: ots[3],
        pqjxy: ots[4],
        pqzrs: ots[5],
        cqfx: ots[14],
        cqjxy: ots[15],
        cqzrs: ots[16],
        pq1fx: ots[6],
        pq1jxy: ots[7],
        pq2fx: ots[8],
        pq2jxy: ots[9],
        pq3fx: ots[10],
        pq3jxy: ots[11],
        pq4fx: ots[12],
        pq4jxy: ots[13],
        value: ots,
      };
    },
    init(stpes) {
      this.sts.push(
        ...stpes.filter(f =>  f.cmd.startsWith("on"))
                .map(a => new Stanp(a))
                .sort((k,l)=>k.station.localeCompare(l.station)));

      this.ccs.push(...stpes.filter(f=>f.cmd.startsWith("on")&&f.iidinfo&&f.iidinfo.includes('出差')).map(a=>new Stanp(a)));

      this.lunhuangList = stpes
        .filter((f) => f.cmd.endsWith("ing"))
        .map((lh) => lhParse(new Stanp(lh)))
        .sort((a, b) => a.delt - b.delt);
    },
    setservicestauts(v){
      this.$store.commit('mschange',v);
    }
  },

  filters: {
    fixTowNumber(num) {
      return String(num).padStart(2, "0");
    },
  },
  created() {
    StanpAtget(//node home 服务端正常
      (res) => {
        this.setservicestauts(false);
        this.init(res);
      },
      () => {//node home 服务端异常，使用springboot rsf
        console.log("暂时没有备用服务端。")
        // this.setservicestauts(true);
        // ax_get("/rsf/stanp/at", {}, (res)=>this.init(res.data), "http://120.25.102.186:6060");
      }
    );
  },
};
</script>
<style lang="less" >
.warntext{
  color:rgb(177, 177, 19)
}

.imagetable {
  font-family: verdana, arial, sans-serif;
  font-size: 11px;
  color: #333333;
  border-width: 1px;
  border-color: #999999;
  border-collapse: collapse;
  
  th{
    border-width: 0px;
    padding: 8px;
    border-style: solid;
    border-color: #999999;
  }
  td{
      border-width: 0px;
      padding: 8px;
      border-style: solid;
      text-align: center;
      background: lightcyan;
  }

}


  table.sd{
    .imagetable;
    th{
      background: #b5cfd2;
    }
  }
  table.pq{
    .imagetable;
    th{
      background: lightsteelblue;
    }
  }

.totalshow{
  float: left;
}

.sepdived{
    border: 2px solid;
    float: left;
    height: 1px;
    color: white;
  } 


</style>